import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class VisibilityController extends Controller {
  static targets = ["visibility"];

  declare visibilityTargets: HTMLElement[];

  show(e: ActionEvent) {
    e.preventDefault();

    const navTarget = this.findVisibilityTarget((e.currentTarget as HTMLElement).dataset.visibilityTargetName);

    navTarget.classList.add("is-active");
  }

  hide(e: ActionEvent) {
    e.preventDefault();

    const visibilityTarget = this.findVisibilityTarget((e.currentTarget as HTMLElement).dataset.visibilityTargetName);

    visibilityTarget.classList.remove("is-active");
  }

  toggle(e: ActionEvent) {
    e.preventDefault();

    const visibilityTarget = this.findVisibilityTarget((e.currentTarget as HTMLElement).dataset.visibilityTargetName);

    visibilityTarget.classList.toggle("is-active");
  }

  private findVisibilityTarget(name?: string) {
    if (!name) throw "Missing visibility target name";

    const visibilityTarget = this.visibilityTargets.find(target => target.dataset.visibilityTargetName === name);

    if (!visibilityTarget) throw `Unable to find visibility target with name: ${name}`;

    return visibilityTarget;
  }
}
