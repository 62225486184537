import { Controller } from "@hotwired/stimulus";
import { dynamicBackgroundImageSet, literalBackgroundImageSet } from "src/image_set";

export default class LazyImagesController extends Controller<HTMLElement> {
  static targets = ["image"];

  observer?: IntersectionObserver;

  imageTargetConnected(target: HTMLElement) {
    if (!this.observer) {
      this.observer = this.setupObserver();
    }

    this.observer.observe(target);
  }

  reconnectTarget(event: Event) {
    if (!this.observer) {
      this.observer = this.setupObserver();
    }

    if (event.target instanceof Element) {
      this.observer.observe(event.target);
    }
  }

  private onIntersection(entries: IntersectionObserverEntry[]) {
    for (const entry of entries) {
      const imageryPath = entry.target.getAttribute("data-lazy-images-imagery-path-value");
      const imageryVariant = entry.target.getAttribute("data-lazy-images-imagery-variant");
      const webp2xValue = entry.target.getAttribute("data-lazy-images-webp2x-value");
      const webp1xValue = entry.target.getAttribute("data-lazy-images-webp1x-value");
      const jpeg2xValue = entry.target.getAttribute("data-lazy-images-jpeg2x-value");

      if (entry.isIntersecting) {
        this.observer?.unobserve(entry.target);

        if (imageryPath) {
          entry.target.setAttribute("style", dynamicBackgroundImageSet(imageryPath, imageryVariant));
        } else if (webp2xValue && webp1xValue && jpeg2xValue) {
          entry.target.setAttribute("style", literalBackgroundImageSet(webp2xValue, webp1xValue, jpeg2xValue));
        }
      }
    }
  }

  private setupObserver(): IntersectionObserver {
    const opts: IntersectionObserverInit = {
      rootMargin: "180px"
    };

    return new IntersectionObserver(this.onIntersection.bind(this), opts);
  }
}
